<template>
  <div class="content">
    <PageHeader :title="$t('acc-settings.header')"/>
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-6">
          <CardComponent>
            <template #header>
              {{ $tc('app.information', 2) }}
            </template>
            <FormComponent @submit="submitData">
              <FormGroup>
                <AvatarComponent :image="form.profile_picture" :name="form.name" class="mr-2"
                                 size="lg"></AvatarComponent>
                <ButtonComponent class="mr-2" @click="upload">Upload</ButtonComponent>
                <ButtonComponent variant="secondary" @click="deleteAvatar">{{ $tc('app.delete') }}</ButtonComponent>
                <UploadComponent ref="uploadMenu" :preview="true" aspect-ratio="1" accept="image/*" @uploaded="changeAvatar" is-avatar></UploadComponent>
              </FormGroup>
              <div class="row">
                <div class="col-6">
                  <InputComponent v-model="form.name" :label="$tc('app.name')" required></InputComponent>
                  <InputComponent v-model="form.email" :label="$tc('app.email')" required></InputComponent>
                </div>
                <div class="col-6">
                  <TelInputComponent v-model="form.mobile_number" :label="$tc('app.mobile_number')"></TelInputComponent>
                  <PasswordInputComponent v-model="form.password" :label="$tc('app.password')"></PasswordInputComponent>
                </div>
              </div>
              <FormGroup :label="$tc('app.language')">
                <OptLang/>
              </FormGroup>
              <ButtonComponent type="submit" :loading="isSending">{{ $tc('app.update') }}</ButtonComponent>
            </FormComponent>
          </CardComponent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import UserService from '@/services/user.service';
import PageHeader from '@/components/PageHeader.vue';
import OptLang from '@/components/rebranding/OptLang.vue';
import CardComponent from '@/components/CardComponent.vue';
import FormGroup from '@/components/form/FormGroupComponent.vue';
import AvatarComponent from '@/components/ui/AvatarComponent.vue';
import UploadComponent from '@/components/ui/UploadComponent/Index.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import TelInputComponent from '@/components/form/TelInputComponent.vue';
import PasswordInputComponent from '@/components/form/PasswordInputComponent.vue';
import FormComponent from '@/components/form/FormComponent.vue';

export default {
  name: 'UserSettings',
  components: {
    FormComponent,
    PasswordInputComponent,
    TelInputComponent,
    InputComponent,
    ButtonComponent,
    UploadComponent,
    AvatarComponent,
    FormGroup,
    CardComponent,
    PageHeader,
    OptLang,
  },
  data() {
    return {
      isSending: false,
      form: {
        name: '',
        email: '',
        mobile_number: '',
      },
    };
  },
  created() {
    this.fetchInfo();
  },
  methods: {
    fetchInfo() {
      UserService.getInfo().then(
        (response) => {
          response.mobile_number = `+${response.mobile_number}`;
          this.form = response;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    upload() {
      this.$refs.uploadMenu.toggle();
    },
    changeAvatar(data) {
      this.form.profile_picture = `${data.data.path}?${new Date().getTime()}`;
    },
    deleteAvatar() {
      this.form.profile_picture = null;
    },
    submitData() {
      this.isSending = true;
      UserService.updateUserInfo(this.form).then(
        (response) => {
          response.mobile_number = `+${response.mobile_number}`;
          this.form = response;
          this.$store.state.auth.user.profile_picture = this.form.profile_picture;

          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('acc-settings.toast.updated'),
            type: 'success',
          });
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
              error.code
            }`,
            content: error.message,
            type: 'danger',
          });
        },
      ).finally(() => {
        this.isSending = false;
      });
    },
  },
};
</script>

<style lang="scss">
</style>
